import React, { useEffect, useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { Container } from '../container';
import { PageHeading } from '../page-heading';
import { Breadcrumbs } from '../breadcrumbs';
import { PageGrid, PageGridItem } from '../page-grid';
import { SideBarContent } from '../side-bar-content';
import { LinkTyle } from '../link-tyle';
import { useWindowSize } from '../../hooks/use-window-size';
import { PageTitle } from '../page-title';
import { Loader } from '../loader';
import config from '../../config';

const UsefulLinks = () => {
  const [usefulLinks, setUsefulLinks] = useState();
  const { width } = useWindowSize();
  const pages = [{ title: 'Useful Links', active: true }];

  useEffect(() => {
    const getResults = async () => {
      const [linksResults] = await Promise.all([
        axios.get(`${config.baseUrl}/api/v1/page/useful-links`),
      ]);

      setUsefulLinks(linksResults.data.data.collection);
    };
    getResults();
  }, []);

  return (
    <>
      <PageTitle title="Essex Rural Partnership - Useful Links" />
      <PageHeading>
        <Container>
          <h1>Useful Links</h1>
        </Container>
      </PageHeading>
      <Container>
        <Breadcrumbs pages={pages} />
        <Wrapper>
          {usefulLinks && width <= 768 && (
            <LinkGrid>
              {usefulLinks.map((link, index) => (
                <LinkTyle
                  key={`${index}-${link.url}`}
                  title={link.name}
                  url={link.url}
                />
              ))}
            </LinkGrid>
          )}
          {usefulLinks && width > 768 && (
            <PageGrid>
              <PageGridItem
                position="left"
                width={width <= 1024 ? '64%' : '69%'}
              >
                <LinkGrid>
                  {usefulLinks.map((link, index) => (
                    <LinkTyle
                      key={`${index}-${link.url}`}
                      title={link.name}
                      url={link.url}
                    />
                  ))}
                </LinkGrid>
              </PageGridItem>
              <PageGridItem
                position="right"
                width={width <= 1024 ? '34%' : '29%'}
                margin="0.3125rem 0 0 0"
              >
                <StyledSideBarContent />
              </PageGridItem>
            </PageGrid>
          )}
          {!usefulLinks && <Loader />}
        </Wrapper>
      </Container>
    </>
  );
};

const StyledSideBarContent = styled(SideBarContent)`
  margin-top: 20px;
`;

const Wrapper = styled.div`
  margin-top: 2rem;
  margin-bottom: 2rem;
`;

const LinkGrid = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export { UsefulLinks };
