import styled from 'styled-components';

const Container = styled.div`
  padding: 0 17rem;
  @media screen and (max-width: 1775px) {
    padding: 0 10rem;
  }
  @media screen and (max-width: 1550px) {
    padding: 0 6rem;
  }
  @media screen and (max-width: 1421px) {
    padding: 0 3rem;
  }
  @media screen and (max-width: 550px) {
    padding: 0 1rem;
  }
`;

export { Container };
