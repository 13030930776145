import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import styled from 'styled-components';
import { Container } from '../container';
import { Loader } from '../loader';
import { PageGrid, PageGridItem } from '../page-grid';
import { SideBarContent } from '../side-bar-content';
import { PageHeading } from '../page-heading';
import { PageTitle } from '../page-title';
import { Breadcrumbs } from '../breadcrumbs';
import { useWindowSize } from '../../hooks/use-window-size';
import config from '../../config';

const CustomPage = () => {
  const { width } = useWindowSize();
  const [page, setPage] = useState();
  const [status, setStatus] = useState();
  const { pathname } = useLocation();

  useEffect(() => {
    const getResults = async () => {
      const [pageResults] = await Promise.all([
        axios.get(`${config.baseUrl}/api/v1/page${pathname}`),
      ]);

      setPage(pageResults.data);
      setStatus(pageResults.data.status);
    };
    getResults();
  }, []);

  if (page) {
    return (
      <>
        {status === 200 && (
          <>
            <PageTitle
              title={`Essex Rural Partnership - ${page.data[0].title}`}
            />
            <PageHeading>
              <Container>
                <h1>{status === 200 && page.data[0].title}</h1>
              </Container>
            </PageHeading>
          </>
        )}
        <Container>
          {status === 200 && (
            <Breadcrumbs
              pages={[
                {
                  title: status === 200 ? page.data[0].title : '',
                  active: true,
                },
              ]}
            />
          )}
          <Wrapper>
            {status === 200 && width <= 768 && (
              <div dangerouslySetInnerHTML={{ __html: page.data[0].content }} />
            )}
            {status === 200 && width > 768 && (
              <PageGrid>
                <PageGridItem
                  position="left"
                  width={width <= 1024 ? '64%' : '69%'}
                >
                  <div
                    dangerouslySetInnerHTML={{ __html: page.data[0].content }}
                  />
                </PageGridItem>
                <PageGridItem
                  position="right"
                  width={width <= 1024 ? '34%' : '29%'}
                >
                  <SideBarContent />
                </PageGridItem>
              </PageGrid>
            )}
            {status !== 200 && 'Page not found.'}
          </Wrapper>
        </Container>
      </>
    );
  }

  return (
    <Container>
      <Wrapper>
        <Loader />
      </Wrapper>
    </Container>
  );
};

export { CustomPage };

const Wrapper = styled.div`
  margin: 2rem 0;
`;
