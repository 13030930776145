import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import {
  faLeaf,
  faUsers,
  faFileAlt,
  faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons';
import LazyLoad from 'react-lazyload';
import Typography from '@material-ui/core/Typography';
import { SecondaryNavTile } from '../secondary-nav-tyle';
import { LearnMoreTile } from '../learn-more-tile';
import { Container } from '../container';
import { Hero } from '../hero';
import flowers from '../../img/flowers.jpeg';
import banner from '../../img/banner.jpg';
import acorns from '../../img/acorns.jpg';
import theme from '../../theme';
import { Loader } from '../loader';
import config from '../../config';
import { PageTitle } from '../page-title';
import { MainContext } from '../main-context';

const Home = () => {
  const { newsArticles } = useContext(MainContext);
  const colourMap = ['#518301', theme.blue, '#1D1366', '#008040'];
  const [home, setHome] = useState();

  useEffect(() => {
    const getResults = async () => {
      const [contentResults] = await Promise.all([
        axios.get(`${config.baseUrl}/api/v1/page/home`),
      ]);

      setHome(contentResults.data.data.collection);
    };
    getResults();
  }, []);

  return (
    <>
      <PageTitle title="Essex Rural Partnership" />
      <Hero image={banner} text={home ? home.heading : ''} isHomePage />
      <Welcome>
        <Container>
          {home && home.summary}
          {!home && (
            <ProgressWrapper>
              <Loader />
            </ProgressWrapper>
          )}
        </Container>
      </Welcome>
      <Container>
        <SecondaryNav>
          <SecondaryNavTile
            icon={faLeaf}
            text="RURAL STRATEGY"
            link="/essex-rural-strategy"
          />
          <SecondaryNavTile
            icon={faUsers}
            text="EVENTS"
            link="/meetings-and-events"
          />
          <SecondaryNavTile
            icon={faFileAlt}
            text="NEWSLETTER"
            link="/resources"
            hash="#newsletters"
          />
          <SecondaryNavTile
            icon={faExclamationTriangle}
            text="FLY TIPPING"
            link="/rural-crime/fly-tipping"
          />
        </SecondaryNav>
      </Container>
      <Container>
        <Vision>
          Our vision is for 'A county which engages, values and respects its
          rural environment; and where rural communities fully contribute to and
          benefit from a healthy, prosperous and connected Essex'.
        </Vision>
      </Container>
      <Container>
        <SectionHeading>Latest News</SectionHeading>
        {newsArticles ? (
          <NewsGrid>
            {newsArticles.slice(0, 4).map((article, index) => (
              <LearnMoreTile
                key={article.id}
                text={article.heading}
                image={
                  article.image
                    ? `${config.baseUrl}/public/${article.image}`
                    : acorns
                }
                colour={colourMap[index]}
                link={`/news/${article.id}`}
                snippet={article.overview}
              />
            ))}
          </NewsGrid>
        ) : (
          <ProgressWrapper>
            <Loader />
          </ProgressWrapper>
        )}
      </Container>
      <Container>
        <SectionHeading marginBottom="1rem">Featured Documents</SectionHeading>
        <LazyLoad once>
          <DocumentsGrid>
            {home &&
              home.featuredDocuments.map((document, index) => (
                <DocumentAnchor
                  key={`document-${index}`}
                  href={document.document}
                  target="_blank"
                  rel="noopenner noreferrer"
                >
                  <Typography variant="srOnly">{`Download ${document.name}`}</Typography>
                  <img
                    src={document.image}
                    alt={`featured document ${document.name || ''}`}
                    width="100%"
                    height="100%"
                  />
                </DocumentAnchor>
              ))}
            {!home && (
              <ProgressWrapper>
                <Loader />
              </ProgressWrapper>
            )}
          </DocumentsGrid>
        </LazyLoad>
      </Container>
      <LazyLoad once>
        <Subscribe>
          <Container>
            <SubscribePrompt href="mailto:info@essexruralpartnership.org.uk?subject=Newsletter">
              <div>
                <span>Sign up to our newsletter</span>
              </div>
            </SubscribePrompt>
          </Container>
        </Subscribe>
      </LazyLoad>
    </>
  );
};

const DocumentAnchor = styled.a`
  padding: 0;
  display: block;
  width: 23%;
  box-shadow: 4px 4px 10px 1px rgba(86, 84, 84, 0.39);
  margin-bottom: 1rem;
  cursor: pointer;
  &:hover {
    transform: scale(1.025);
    box-shadow: 4px 4px 10px 5px rgba(86, 84, 84, 0.39);
  }
  transition: all 0.25s ease-out;
  @media screen and (max-width: 768px) {
    width: 49%;
  }
  @media screen and (max-width: 375px) {
    width: 100%;
  }
`;

const DocumentsGrid = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 2rem;
  padding: 1rem 1.5rem 1.5rem 1.5rem;
  @media screen and (max-width: 375px) {
    justify-content: center;
  }
  a {
    display: block;
    text-decoration: none;
  }
`;

const SectionHeading = styled.h2`
  font-weight: 600;
  text-align: center;
  margin-bottom: ${({ marginBottom }) => marginBottom && marginBottom};
`;

const NewsGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 2.5rem;
`;

const SubscribePrompt = styled.a`
  color: white;
  padding: 2rem;
  border: 0.5rem solid white;
  display: flex;
  align-items: center;
  justify-content: center;
  div {
    text-align: center;
    text-transform: uppercase;
    span {
      font-size: 2.5rem;
    }
  }
  @media screen and (max-width: 900px) {
    div {
      span {
        font-size: 2rem;
      }
    }
  }
  @media screen and (max-width: 745px) {
    border: 0.3rem solid white;
    padding: 1.5rem;
    div {
      span {
        font-size: 1.8rem;
      }
    }
  }
  @media screen and (max-width: 600px) {
    padding: 1rem;
    div {
      span {
        font-size: 1.5rem;
      }
    }
  }
`;

const Subscribe = styled.section`
  background-image: url(${flowers});
  background-position: center;
  background-size: cover;
  height: 40vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: 700;
`;

const Vision = styled.div`
  font-weight: 500;
  font-size: 1.5rem;
  text-align: center;
  padding-bottom: 2rem;
  @media screen and (max-width: 1070px) {
    padding-left: 4rem;
    padding-right: 4rem;
    padding-bottom: 2rem;
  }
  @media screen and (max-width: 900px) {
    font-size: 1.5rem;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-bottom: 2rem;
  }
  @media screen and (max-width: 500px) {
    font-size: 1.25rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 2rem;
  }
`;

const SecondaryNav = styled.nav`
  padding-top: 2rem;
  padding-bottom: 2rem;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

const Welcome = styled.section`
  padding-top: 2rem;
  padding-bottom: 2rem;
  font-weight: 500;
  font-size: 1.5rem;
  background: ${theme.blue};
  color: white;
  @media screen and (max-width: 650px) {
    font-size: 1.25rem;
  }
  @media screen and (max-width: 550) {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
`;

const ProgressWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
`;

export { Home };
