import { createGlobalStyle } from 'styled-components';
import theme from './theme';

const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    line-height: 1.25;
  }

  html {
    margin: 0;
  }

  body {
    margin: 0;
    padding: 0;
    font-family: 'Poppins', Arial, Helvetica, sans-serif;
    font-size: 1rem;
  }

  div, section {
    overflow: hidden;
  }

  a {
    text-decoration: none;
    color: ${theme.blue};
  }

  p {
    margin: 1rem 0;
  }

  h2 {
    color: ${theme.blue};
    margin-bottom: 2rem;
    font-weight: 600;
  }

  .fly-tipping-image, .about-us-image, .essex-rural-strategy-image, .meetings-image, .contact-us-image, .rural-services-survey-image {
    width: 100%;
    display: block;
    margin-bottom: 1rem;
  }

  .essex-rural-strategy-links {
    list-style: none;
    li {
      padding: 0.5rem 0;
    }
  }

  .report-fly-tipping-table {
    table {
      min-width: 415px;;
      th {
        padding-bottom: 0.5rem;
        text-align: left;
        border-bottom: 1px solid ${theme.blue};
      }
      td {
        padding: 0.5rem 0.5rem 0.5rem 0;
      }
      tr {
        height: 2.5rem;
        &:nth-child(even) {
          background: aliceblue;
        }
      }
      a {
        color: ${theme.blue};
        font-weight: 600;
      }
    }
    margin-top: 2rem;
    @media(max-width: 443px) {
      overflow: scroll;
    }
  }

  .fly-tipping-heading {
    text-transform: none;
    margin-bottom: 1rem;
    font-weight: 600;
  }

  .eap-wrapper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    h3, h4 {
      width: 100%;
      text-align: center;
    }
  }

  .eap-group {
    width: 49%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 1rem;
    @media(max-width: 1021px) {
      width: 100%;
    }
  }

  .eap-main-content {
    width: 49%;
    padding: 1rem;
    height: 30rem;
    ul {
      margin-left: 1rem;
    }
    @media(max-width: 1617px) {
      height: 35rem;
    }
    @media(max-width: 1291px) {
      height: 40rem;
    }
    @media(max-width: 1197px) {
      height: 45rem;
    }
    @media(max-width: 1055px) {
      height: 50rem;
    }
    @media(max-width: 1021px) {
      height: 30rem;
    }
    @media(max-width: 780x) {
      height: 35rem;
    }
    @media(max-width: 791px) {
      height: 36rem;
    }
    @media(max-width: 768px) {
      height: 25rem;
    }
    @media(max-width: 667px) {
      height: 30rem;
    }
    @media(max-width: 473px) {
      height: 34rem;
    }
    @media(max-width: 441px) {
      height: 37rem;
    }
    @media(max-width: 409px) {
      height: 42rem;
    }
    @media(max-width: 355px) {
      height: 45rem;
    }
    @media(max-width: 350px) {
      font-size: 0.9rem;
      height: 40rem;
    }
    @media(max-width: 329px) {
      font-size: 0.9rem;
      height: 45rem;
    }
  }

  .eap-group-title {
    padding: 1rem;
    margin-bottom: 0.5rem;
    width: 100%;
  }

  .fly-tipping-light {
    background: #C6F6D5;
  }

  .fly-tipping-moderate {
    background: #9AE6B4;
  }

  .fly-tipping-bad {
    background: #68D391;
  }

  .fly-tipping-very-bad {
    background: #48BB78;
  }

  .location-area {
    margin-top: 2rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .location-box {
    width: ${({ width }) => width};
    margin-bottom: 2rem;
    @media screen and (max-width: 1200px) {
      width: 100%;
    }
  }

  .contact-us-title {
    display: block;
    font-weight: 700;
    margin-bottom: 1rem;
  }

  .contact-us-address {
    list-style: none;
   margin-bottom: 2rem;
    li {
    margin-top: 0.25rem;
    }
  }
`;

export { GlobalStyle };
