import React, { useEffect, useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { PageGrid, PageGridItem } from '../../page-grid';
import { useWindowSize } from '../../../hooks/use-window-size';
import { SideBarContent } from '../../side-bar-content';
import { Loader } from '../../loader';
import config from '../../../config';

const FlyTipping = () => {
  const { width } = useWindowSize();
  const [page, setPage] = useState();

  useEffect(() => {
    const getResults = async () => {
      const [documentsResults] = await Promise.all([
        axios.get(`${config.baseUrl}/api/v1/page/fly-tipping`),
      ]);

      setPage(documentsResults.data.data[0]);
    };
    getResults();
  }, []);

  if (width <= 768) {
    return (
      <Wrapper>
        {page && (
          <HTMLWrapper dangerouslySetInnerHTML={{ __html: page.content }} />
        )}
        {!page && <Loader />}
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <PageGrid>
        <PageGridItem position="left" width={width <= 1024 ? '64%' : '69%'}>
          {page && (
            <HTMLWrapper dangerouslySetInnerHTML={{ __html: page.content }} />
          )}
          {!page && <Loader />}
        </PageGridItem>
        <PageGridItem position="right" width={width <= 1024 ? '34%' : '29%'}>
          <SideBarContent />
        </PageGridItem>
      </PageGrid>
    </Wrapper>
  );
};

const HTMLWrapper = styled.div`
  margin-bottom: 2rem;
`;

const Wrapper = styled.div`
  margin-top: 1rem;
  margin-bottom: 2rem;
`;

export { FlyTipping };
