import styled from 'styled-components';

const DocumentGrid = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 1rem;
`;

export { DocumentGrid };
