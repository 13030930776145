import React, { useState } from 'react';

const MainContext = React.createContext();

const MainProvider = ({ children }) => {
  const [newsArticles, setNewsArticles] = useState();
  const [pageCount, setPageCount] = useState(10);
  const [home, setHome] = useState();
  const [aboutUs, setAboutUs] = useState();
  const [essexRuralStrat, setEssexRuralStrat] = useState();
  const [flyTipping, setFlyTipping] = useState();
  const [
    essexPoliceRuralCrimeStrat,
    setEssexPoliceRuralCrimeStrat,
  ] = useState();
  const [meetingsAndEvents, setMeetingsAndEvents] = useState();
  const [contact, setContact] = useState();
  const [ruralServicesSurvey, setRuralServicesSurvey] = useState();

  return (
    <MainContext.Provider
      value={{
        newsArticles,
        pageCount,
        home,
        aboutUs,
        essexRuralStrat,
        flyTipping,
        essexPoliceRuralCrimeStrat,
        meetingsAndEvents,
        contact,
        ruralServicesSurvey,
        setRuralServicesSurvey,
        setNewsArticles,
        setPageCount,
        setHome,
        setAboutUs,
        setEssexRuralStrat,
        setFlyTipping,
        setEssexPoliceRuralCrimeStrat,
        setMeetingsAndEvents,
        setContact,
      }}
    >
      {children}
    </MainContext.Provider>
  );
};

export { MainContext, MainProvider };
