import React from 'react';
import styled from 'styled-components';
import ruralSurvey from '../../img/ruralSurvey.jpg';
import { Container } from '../container';
import { PageHeading } from '../page-heading';
import { GreenAccent } from '../green-accent';
import { PageGrid, PageGridItem } from '../page-grid';
import { SideBarContent } from '../side-bar-content';
import { useWindowSize } from '../../hooks/use-window-size';
import { Breadcrumbs } from '../breadcrumbs';
import theme from '../../theme';
import { PageTitle } from '../page-title';

const RuralSurvey = () => {
  const { width } = useWindowSize();

  const pages = [{ title: 'Rural Services Survey', active: true }];

  return (
    <>
      <PageTitle title="Essex Rural Partnership - Rural Services Survey" />
      <PageHeading>
        <Container>
          <h1>
            <GreenAccent>ERP</GreenAccent> Rural Services Survey
          </h1>
        </Container>
      </PageHeading>
      <Container>
        <Breadcrumbs pages={pages} />
        <Wrapper>
          {width <= 768 && (
            <>
              <RuralSurveyImage />
              <MainContent />
            </>
          )}
          {width > 768 && (
            <PageGrid>
              <PageGridItem
                position="left"
                width={width <= 1024 ? '64%' : '69%'}
              >
                <RuralSurveyImage />
                <MainContent />
              </PageGridItem>
              <PageGridItem
                position="right"
                width={width <= 1024 ? '34%' : '29%'}
              >
                <SideBarContent />
              </PageGridItem>
            </PageGrid>
          )}
        </Wrapper>
      </Container>
    </>
  );
};

const MainContent = () => (
  <div>
    <p>
      In 2008 the Essex Rural Partnership (ERP) carried out a survey of services
      in rural areas across Essex, which showed that almost 50% of parishes had
      no shop or post office and only 32% possessed a GP surgery, even on a
      part-time basis. The information collected was used in a variety of ways,
      including to secure funding to help improve access to services in rural
      areas
    </p>
    <p>
      Download the first edition of the{' '}
      <Anchor href="/">2008 Rural Services Survey Report</Anchor>
    </p>
    <p>
      As an update to the 2008 survey, a survey of Services in Rural Essex was
      carried out in 2010/11 to examine the changing nature and awareness of
      services in rural areas. The updated information will be used by the ERP
      and its constituent members to continue to champion the needs of rural
      communities.
    </p>
    <p>
      Download the <Anchor href="/">Services in Rural Essex 2011 Report</Anchor>
    </p>
  </div>
);

const Anchor = styled.a`
  color: ${theme.blue};
  font-weight: 500;
`;

const Wrapper = styled.div`
  margin-top: 2rem;
  margin-bottom: 2rem;
`;

const RuralSurveyImage = styled.div`
  background-image: url(${ruralSurvey});
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 50vh;
  margin-bottom: 1rem;
`;

export { RuralSurvey };
