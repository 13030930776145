import { useEffect, useState } from 'react';
import axios from 'axios';
import { DocumentTyle } from './document-tyle';
import { DocumentGrid } from './DocumentGrid';
import { DocumentsHeading } from './DocumentsHeading';
import { Loader } from '../../loader';
import { BackToTop } from './BackToTop';
import config from '../../../config';

const Presentations = () => {
  const [documents, setDocuments] = useState();

  useEffect(() => {
    const getResults = async () => {
      const [documentsResults] = await Promise.all([
        axios.get(`${config.baseUrl}/api/v1/page/presentations`),
      ]);

      setDocuments(documentsResults.data.data.collection);
    };
    getResults();
  }, []);
  return (
    <>
      <DocumentsHeading id="presentations">Presentations</DocumentsHeading>
      <DocumentGrid>
        {documents &&
          documents.map((document, index) => (
            <DocumentTyle
              key={`${index}-${document.url}`}
              title={document.name}
              url={document.url}
            />
          ))}
        {!documents && <Loader />}
      </DocumentGrid>
      <BackToTop />
    </>
  );
};

export { Presentations };
