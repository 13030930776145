import styled from 'styled-components';
import CircularProgress from '@material-ui/core/CircularProgress';
import theme from '../theme';

const Loader = styled(CircularProgress)`
  &.MuiCircularProgress-colorPrimary {
    color: ${theme.green};
  }
`;

export { Loader };
