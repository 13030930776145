import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const NewsAside = styled.aside`
  background: #518301;
  padding: 2rem 1.5rem;
  color: white;
  h2 {
    color: white;
    text-align: center;
    font-weight: 600;
  }
  p {
    margin: 0.5rem 0;
  }
`;

const NewsAsideItem = ({ heading, snippet, link }) => (
  <NewsItem>
    <h3>{heading}</h3>
    <p>{snippet}</p>
    <LearnMoreLink to={link}>Learn More</LearnMoreLink>
  </NewsItem>
);

const NewsItem = styled.div`
  margin-bottom: 1.5rem;
`;

const LearnMoreLink = styled(Link)`
  font-weight: 600;
  cursor: pointer;
  color: white;
`;

export { NewsAside, NewsAsideItem };
