import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import theme from '../../../theme';

const Headline = ({ date, image, title, link }) => {
  const longTitle = title.length >= 65;

  return (
    <Wrapper image={image}>
      <DetailsWrapper>
        <DateLine>{date}</DateLine>
        <TitleWrapper longTitle={longTitle}>
          <Title longTitle={longTitle}>{title}</Title>
        </TitleWrapper>
        <Link to={link}>
          <ClickHere>Click here to read more</ClickHere>
        </Link>
      </DetailsWrapper>
    </Wrapper>
  );
};

const DetailsWrapper = styled.div`
  background: rgba(0, 0, 0, 0.2);
  padding: 2rem 2rem;
  height: 100%;
`;

const TitleWrapper = styled.div`
  width: ${({ longTitle }) => (longTitle ? '75%' : '60%')};
  @media screen and (max-width: 1300px) {
    width: 75%;
  }
  @media screen and (max-width: 425px) {
    width: 100%;
  }
`;

const DateLine = styled.div`
  font-size: 1.5rem;
  text-transform: uppercase;
`;

const Title = styled.h2`
  font-size: 3rem;
  font-weight: 600;
  color: white;
  margin-bottom: 3rem;
  @media screen and (max-width: 1359px) {
    ${({ longTitle }) => longTitle && 'font-size: 2.5rem;'}
  }
  @media screen and (max-width: 1200px) {
    font-size: 2.5rem;
  }
  @media screen and (max-width: 823px) {
    ${({ longTitle }) => longTitle && 'font-size: 2rem;'}
  }
  @media screen and (max-width: 475px) {
    ${({ longTitle }) =>
      longTitle ? 'font-size: 1rem !important;' : 'font-size: 1.5rem;'}
    font-size: 1.5rem;
  }
`;

const ClickHere = styled.a`
  background: ${theme.blue};
  text-transform: uppercase;
  color: white;
  font-size: 1.25rem;
  font-weight: 500;
  padding: 0.5rem;
  border-radius: 0.125rem;
  @media screen and (max-width: 700px) {
    font-size: 1rem;
  }
  @media screen and (max-width: 350px) {
    text-transform: none;
  }
  @media screen and (max-width: 320px) {
    text-transform: none;
    background: transparent;
    padding: 0;
    font-weight: 700;
  }
`;

const Wrapper = styled.div`
  margin-top: 2rem;
  height: 50vh;
  color: white;
  ${({ image }) => `background-image: url(${image})`};
  background-size: cover;
  background-position: center;
  position: relative;
`;

export { Headline };
