import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import theme from '../../../theme';

const SmallArticle = ({ id, date, image, title, snippet }) => (
  <Wrapper to={`./news/${id}`}>
    <Image image={image} />
    <Content>
      <Date>{date}</Date>
      <Heading>{title}</Heading>
      <p>{`${snippet.substring(0, 100)}...`}</p>
    </Content>
  </Wrapper>
);

const Wrapper = styled(Link)`
  width: 49%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 1rem;
  color: black;
  @media screen and (max-width: 900px) {
    width: 100%;
  }
`;

const Image = styled.div`
  height: 15rem;
  width: 48%;
  ${({ image }) => `background: url(${image})`};
  background-size: cover;
  background-position: center;
  @media screen and (max-width: 700px) {
    width: 100%;
    height: 20rem;
    margin-bottom: 1rem;
  }
`;

const Content = styled.div`
  width: 48%;
  p {
    margin-top: 0.5rem;
  }
  @media screen and (max-width: 700px) {
    width: 100%;
  }
`;

const Date = styled.span`
  color: ${theme.green};
  font-weight: 600;
`;

const Heading = styled.h2`
  color: ${theme.blue};
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 0;
  @media screen and (max-width: 1270px) {
    font-size: 1.25rem;
    width: 100%;
  }
  @media screen and (max-width: 700px) {
    width: 100%;
  }
`;

export { SmallArticle };
