const getSnippetSubstring = (snippetText, maxLength) => {
  if (snippetText.length <= maxLength) {
    return `${snippetText}...`;
  }

  // trim the string to the maximum length
  const trimmedString = snippetText.substr(0, maxLength);

  // re-trim if we are in the middle of a word
  const trimmedStringToNearestWord = trimmedString.substr(
    0,
    Math.min(trimmedString.length, trimmedString.lastIndexOf(' '))
  );

  return trimmedStringToNearestWord ? `${trimmedStringToNearestWord}...` : '';
};

export { getSnippetSubstring };
