import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Close from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import styled from 'styled-components';
import theme from '../theme';

const MobileNav = ({ show, closeMobileNav }) => {
  const [showRuralCrime, setShowRuralCrime] = useState(false);
  const { pathname } = useLocation();

  return (
    <Nav show={show}>
      <CloseButton onClick={closeMobileNav}>
        <Typography variant="srOnly">Close Menu</Typography>
        <Close />
      </CloseButton>
      <MenuItems>
        <MenuLink
          to="/about-us"
          onClick={closeMobileNav}
          active={pathname.includes('about-us')}
        >
          About Us
        </MenuLink>
        <MenuLink
          to="/essex-rural-strategy"
          onClick={closeMobileNav}
          active={pathname.includes('essex-rural-strategy')}
        >
          Essex Rural Strategy
        </MenuLink>
        <MenuLink
          to="/"
          onClick={() => setShowRuralCrime(!showRuralCrime)}
          opened={showRuralCrime}
          active={pathname.includes('rural-crime')}
        >
          Rural Crime
          <ExpandMore />
        </MenuLink>
        <SubMenu show={showRuralCrime} height="7.5rem">
          <SubMenuLink to="/rural-crime/fly-tipping" onClick={closeMobileNav}>
            Fly Tipping
          </SubMenuLink>
          <SubMenuLink
            to="/rural-crime/essex-police-rural-crime-strategy"
            onClick={closeMobileNav}
          >
            Essex Police Rural Crime Strategy
          </SubMenuLink>
          <SubMenuLink
            to="/domesticabusehelp"
            onClick={closeMobileNav}
          >
             Domestic Abuse - Help
          </SubMenuLink>
        </SubMenu>
        <MenuLink
          to="/news"
          onClick={closeMobileNav}
          active={pathname.includes('news')}
        >
          News
        </MenuLink>
        <MenuLink
          to="/meetings-and-events"
          onClick={closeMobileNav}
          active={pathname.includes('meetings-and-events')}
        >
          Meetings and Events
        </MenuLink>
        <MenuLink
          to="/resources"
          onClick={closeMobileNav}
          active={pathname.includes('resources')}
        >
          Resources
        </MenuLink>
        <MenuLink
          to="/useful-links"
          onClick={closeMobileNav}
          active={pathname.includes('useful-links')}
        >
          Useful Links
        </MenuLink>
        <MenuLink
          to="/contact"
          onClick={closeMobileNav}
          active={pathname.includes('contact')}
        >
          Contact
        </MenuLink>
      </MenuItems>
    </Nav>
  );
};

const CloseButton = styled.button`
  position: fixed;
  top: 1rem;
  right: 1rem;
  background: transparent;
  border: none;
  cursor: pointer;
  color: ${theme.blue};
  font-weight: 700;
`;

const SubMenu = styled.div`
  height: ${({ show, height }) => (show ? height : '0')};
  border-bottom: ${({ show }) => (show ? '1px solid #ccc' : 'none')};
  transition: all 0.25s ease-out;
`;

const MenuLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ active }) => (active ? `${theme.green}` : `${theme.blue}`)};
  font-weight: 600;
  text-align: center;
  &:first-child {
    border-top: 1px solid #ccc;
  }
  border-bottom: ${({ opened }) => (opened ? 'none' : '1px solid #ccc')};
  padding: 1rem 0;
`;

const SubMenuLink = styled(Link)`
  display: block;
  color: ${theme.blue};
  font-weight: 500;
  text-align: center;
  padding: 0.5rem 0;
  &:last-child {
    padding-bottom: 1rem;
  }
`;

const MenuItems = styled.div`
  margin-top: 3.5rem;
`;

const Nav = styled.div`
  transform: ${({ show }) => (show ? 'none' : 'translateX(100%)')};
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  background: white;
  height: 100vh;
  width: 100vw;
  transition: all 0.4s ease-out;
  overflow: scroll;
`;

export { MobileNav };
