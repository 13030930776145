import React from 'react';
import styled from 'styled-components';
import { Anchor } from '../../anchor';
import theme from '../../../theme';

const BackToTop = () => {
  const animateToTop = () => {
    const topElement = document.getElementById('main-nav');

    topElement.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <Wrapper>
      <BackToTopAnchor onClick={animateToTop}>Back to top</BackToTopAnchor>
    </Wrapper>
  );
};

const BackToTopAnchor = styled(Anchor)`
  transition: all 0.25s ease-in-out;
  &:hover {
    color: ${theme.green};
  }
`;

const Wrapper = styled.div`
  text-align: center;
`;

export { BackToTop };
