import React, { useEffect, useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { PageGrid, PageGridItem } from '../../page-grid';
import { useWindowSize } from '../../../hooks/use-window-size';
import { SideBarContent } from '../../side-bar-content';
import { Loader } from '../../loader';
import config from '../../../config';

const RuralCrimeStrategy = () => {
  const { width } = useWindowSize();
  const [page, setPage] = useState();

  useEffect(() => {
    const getResults = async () => {
      const [pageResults] = await Promise.all([
        axios.get(
          `${config.baseUrl}/api/v1/page/essex-police-rural-crime-strategy-main`
        ),
      ]);

      setPage(pageResults.data.data[0]);
    };
    getResults();
  }, []);

  if (width <= 768) {
    return (
      <Wrapper>
        {page && (
          <div
            dangerouslySetInnerHTML={{
              __html: page.content,
            }}
          />
        )}
        {!page && <Loader />}
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <PageGrid>
        <PageGridItem position="left" width={width <= 1024 ? '64%' : '69%'}>
          {page && (
            <div
              dangerouslySetInnerHTML={{
                __html: page.content,
              }}
            />
          )}
          {!page && <Loader />}
        </PageGridItem>
        <PageGridItem position="right" width={width <= 1024 ? '34%' : '29%'}>
          <SideBarContent />
        </PageGridItem>
      </PageGrid>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-top: 2rem;
`;

export { RuralCrimeStrategy };
