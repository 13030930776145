import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import styled from 'styled-components';
import { Anchor } from '../../anchor';
import theme from '../../../theme';

const Sublink = ({ link, index, sublinks, scrollIntoView }) => (
  <>
    <SublinkAnchor
      onClick={() => scrollIntoView(link.href)}
      key={`sublink-${index}`}
      id={`sublink-${index}`}
    >
      {link.text}
    </SublinkAnchor>
    {index !== sublinks.length - 1 && <Pipe>|</Pipe>}
  </>
);

const SubMenu = ({ sublinks }) => {
  const isMobile = useMediaQuery('(max-width:425px)');
  const scrollIntoView = (id) => {
    const el = document.getElementById(id);
    el.scrollIntoView({ behavior: 'smooth' });
  };

  if (isMobile) {
    return (
      <SubmenuList>
        {sublinks.map((link, index) => (
          <li key={`sublink-${index}`}>
            <SublinkAnchor onClick={() => scrollIntoView(link.href)}>
              {link.text}
            </SublinkAnchor>
          </li>
        ))}
      </SubmenuList>
    );
  }

  return (
    <SubMenuWrapper>
      {sublinks.map((link, index) => (
        <Sublink
          key={`sublink-${index}`}
          link={link}
          index={index}
          sublinks={sublinks}
          scrollIntoView={scrollIntoView}
        />
      ))}
    </SubMenuWrapper>
  );
};

const SubmenuList = styled.ul`
  list-style: none;
`;

const SubMenuWrapper = styled.nav`
  margin-bottom: 2rem;
  max-width: 46rem;
`;

const SublinkAnchor = styled(Anchor)`
  display: inline-block;
  line-height: 1.5;
  transition: all 0.25s ease-in-out;
  &:hover {
    color: ${theme.green};
  }
`;

const Pipe = styled.span`
  color: ${theme.blue};
  margin: 1rem;
`;

export { SubMenu };
