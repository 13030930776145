import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { format } from 'date-fns';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Container } from '../../container';
import { GreenAccent } from '../../green-accent';
import { PageHeading } from '../../page-heading';
import theme from '../../../theme';
import { Breadcrumbs } from '../../breadcrumbs';
import { Loader } from '../../loader';
import config from '../../../config';
import { PageTitle } from '../../page-title';
import defaultImage from '../../../img/bannerCropped.jpg';

const NewsStory = () => {
  const { id } = useParams();
  const [article, setArticle] = useState();
  const [pages, setPages] = useState([{ title: 'News', link: `/news` }]);

  useEffect(() => {
    const getResults = async () => {
      const results = await axios.get(`${config.baseUrl}/api/v1/news/${id}`);
      const resultsArticle = results.data.data.articles[0];
      setArticle(resultsArticle);
      setPages([...pages, { title: resultsArticle.heading, active: true }]);
    };

    if (!article) {
      getResults();
    }
  }, [article, id, pages]);

  return (
    <>
      <PageTitle title="Essex Rural Partnership - News" />
      <PageHeading>
        <Container>
          <h1>
            <GreenAccent>ERP</GreenAccent> News
          </h1>
        </Container>
      </PageHeading>
      <Wrapper>
        <Container>
          {article ? (
            <>
              <HeadingContainer>
                <NewsDate>
                  {format(new Date(article.date), 'do MMMM yyyy')}
                </NewsDate>
                <NewsHeading>
                  <h2>{article.heading}</h2>
                </NewsHeading>
              </HeadingContainer>
              <Breadcrumbs pages={pages} />
              <StoryContainer>
                <div>
                  <StoryImage
                    src={
                      article.image
                        ? `${config.baseUrl}/public/${article.image}`
                        : defaultImage
                    }
                    width="100%"
                  />
                </div>
                <StoryContent
                  dangerouslySetInnerHTML={
                    article.description
                      ? { __html: article.description }
                      : {
                          __html: '<span>This article has been removed.</span>',
                        }
                  }
                />
              </StoryContainer>
            </>
          ) : (
            <Loader />
          )}
        </Container>
      </Wrapper>
    </>
  );
};

const StoryContent = styled.div`
  p {
    margin-top: 0;
  }
`;

const StoryImage = styled.img`
  @media screen and (max-width: 768px) {
    margin-bottom: 1rem;
  }
`;

const StoryContainer = styled.div`
  margin-top: 2rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  div {
    width: 49%;
    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }
`;

const HeadingContainer = styled.div`
  border-bottom: 0.0625rem solid ${theme.blue};
`;

const NewsDate = styled.div`
  color: ${theme.green};
  font-size: 1.5rem;
  font-weight: 700;
`;

const NewsHeading = styled.div`
  width: 75%;
  h2 {
    color: ${theme.blue};
    font-size: 3rem;
    font-weight: 600;
    @media screen and (max-width: 425px) {
      font-size: 2rem;
    }
  }
`;

const Wrapper = styled.div`
  margin: 2rem 0;
`;

export { NewsStory };
