import styled from 'styled-components';

const PageGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const PageGridItem = styled.div`
  width: ${({ width }) => `${width}`};
  margin: ${({ margin }) => `${margin}`};
`;

export { PageGrid, PageGridItem };
