import React, { useContext } from 'react';
import styled from 'styled-components';
import LazyLoad from 'react-lazyload';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faLeaf,
  faUsers,
  faFileAlt,
  faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons';
import { MainContext } from './main-context';
import { NewsAside, NewsAsideItem } from './news-aside';
import theme from '../theme';
import flowers from '../img/flowers.jpeg';

const SideBarContent = () => {
  const { newsArticles } = useContext(MainContext);

  return (
    <>
      <NewsAside>
        <h2>Latest News</h2>
        {newsArticles &&
          newsArticles
            .slice(0, 4)
            .map((article) => (
              <NewsAsideItem
                key={article.id}
                heading={article.heading}
                snippet={`${article.overview.substring(0, 100)}...`}
                link={`/news/${article.id}`}
              />
            ))}
      </NewsAside>
      <LazyLoad once>
        <Subscribe>
          <SubscribePrompt href="mailto:info@essexruralpartnership.org.uk?subject=Newsletter">
            <div>
              <span>Sign up to our newsletter</span>
            </div>
          </SubscribePrompt>
        </Subscribe>
      </LazyLoad>
      <SideNav>
        <NavItem to="/rural-survey">
          <span>Rural Strategy</span>
          <div>
            <FontAwesomeIcon icon={faLeaf} />
          </div>
        </NavItem>
        <NavItem to="/meetings-and-events">
          <span>Events</span>
          <div>
            <FontAwesomeIcon icon={faUsers} />
          </div>
        </NavItem>
        <NavItem to={{ pathname: '/resources', hash: '#newsletters' }}>
          <span>NEWSLETTER</span>
          <div>
            <FontAwesomeIcon icon={faFileAlt} />
          </div>
        </NavItem>
        <NavItem to="/rural-crime/fly-tipping">
          <span>Fly Tipping</span>
          <div>
            <FontAwesomeIcon icon={faExclamationTriangle} />
          </div>
        </NavItem>
      </SideNav>
    </>
  );
};

const SideNav = styled.nav`
  margin-top: 3rem;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const NavItem = styled(Link)`
  height: 6.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  width: 50%;
  color: ${theme.blue};
  transition: all 0.25s ease-out;
  &:hover {
    color: ${theme.green};
  }
  margin-bottom: 2rem;
  svg {
    font-size: 4rem;
  }
  span {
    font-weight: 500;
  }
`;

const SubscribePrompt = styled.a`
  color: white;
  padding: 2rem;
  margin: 2rem;
  border: 0.25rem solid white;
  display: flex;
  align-items: center;
  justify-content: center;
  div {
    text-align: center;
    text-transform: uppercase;
    span {
      font-size: 1.25rem;
      @media (max-width: 880px) {
        font-size: 1rem;
      }
      @media (max-width: 800px) {
        font-size: 0.9rem;
      }
    }
  }
`;

const Subscribe = styled.section`
  background-image: url(${flowers});
  background-position: center;
  background-size: cover;
  color: white;
  font-weight: 700;
  margin-top: 2rem;
`;

export { SideBarContent };
