import React from 'react';
import styled from 'styled-components';
import { Container } from './container';

const Hero = ({ image, text }) => (
  <Wrapper image={image}>
    <Container>
      <Heading>{text}</Heading>
    </Container>
  </Wrapper>
);

const Wrapper = styled.div`
  height: 50vh;
  color: white;
  ${({ image }) => `background: url(${image});`}
  background-size: cover;
  background-position: center;
  position: relative;
`;

const Heading = styled.h1`
  font-size: 4.5rem;
  font-weight: 600;
  margin: 0;
  padding: 0;
  position: absolute;
  bottom: 3rem;
  text-transform: uppercase;
  width: 50%;
  @media screen and (max-width: 1845px) {
    font-size: 4rem;
  }
  @media screen and (max-width: 1550px) {
    width: 75%;
    font-size: 4rem;
  }
  @media screen and (max-width: 1200px) {
    font-size: 3.5rem;
  }
  @media screen and (max-width: 720px) {
    font-size: 3.5rem;
  }
  @media screen and (max-width: 650px) {
    font-size: 3rem;
  }
  @media screen and (max-width: 570px) {
    font-size: 2.9rem;
  }
  @media screen and (max-width: 550px) {
    font-size: 2.6rem;
  }
  @media screen and (max-width: 480px) {
    font-size: 2.3rem;
  }
  @media screen and (max-width: 460px) {
    font-size: 2rem;
  }
  @media screen and (max-width: 420px) {
    font-size: 1.8rem;
  }
`;

export { Hero };
