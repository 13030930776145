/* eslint-disable react/no-danger */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { Container } from '../container';
import { PageHeading } from '../page-heading';
import { GreenAccent } from '../green-accent';
import { PageGrid, PageGridItem } from '../page-grid';
import { SideBarContent } from '../side-bar-content';
import { useWindowSize } from '../../hooks/use-window-size';
import { Breadcrumbs } from '../breadcrumbs';
import { PageTitle } from '../page-title';
import { Loader } from '../loader';
import config from '../../config';

const AboutUs = () => {
  const { width } = useWindowSize();
  const [page, setPage] = useState();

  useEffect(() => {
    const getResults = async () => {
      const [pageResults] = await Promise.all([
        axios.get(`${config.baseUrl}/api/v1/page/about`),
      ]);

      setPage(pageResults.data.data[0]);
    };
    getResults();
  }, []);

  const pages = [{ title: 'About ERP', active: true }];

  return (
    <>
      <PageTitle title="Essex Rural Partnership - About Us" />
      <PageHeading>
        <Container>
          <h1>
            About <GreenAccent>ERP</GreenAccent>
          </h1>
        </Container>
      </PageHeading>
      <Container>
        <Breadcrumbs pages={pages} />
        <Wrapper>
          {!page && <Loader />}
          {page && width <= 768 && (
            <div dangerouslySetInnerHTML={{ __html: page.content }} />
          )}
          {page && width > 768 && (
            <PageGrid>
              <PageGridItem
                position="left"
                width={width <= 1024 ? '64%' : '69%'}
              >
                <div dangerouslySetInnerHTML={{ __html: page.content }} />
              </PageGridItem>
              <PageGridItem
                position="right"
                width={width <= 1024 ? '34%' : '29%'}
              >
                <SideBarContent />
              </PageGridItem>
            </PageGrid>
          )}
        </Wrapper>
      </Container>
    </>
  );
};

const Wrapper = styled.div`
  margin-top: 2rem;
  margin-bottom: 2rem;
`;

export { AboutUs };
