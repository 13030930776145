import { useEffect, useContext } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import ReactGA from 'react-ga';
import axios from 'axios';
import { GlobalStyle } from './global-style';
import { Home } from './components/pages/home';
import { News } from './components/pages/news/news';
import { NewsStory } from './components/pages/news/news-story';
import { EssexRuralStrategy } from './components/pages/essex-rural-strategy';
import { RuralCrime } from './components/pages/rural-crime/rural-crime';
import { AboutUs } from './components/pages/about-us';
import { CustomPage } from './components/pages/CustomPage';
import { Footer } from './components/footer';
import { MainNav } from './components/main-nav';
import ScrollToTop from './components/scroll-to-top';
import { MeetingsAndEvents } from './components/pages/meetings-and-events';
import { ResourcesPage } from './components/pages/resources/resources-page';
import { UsefulLinks } from './components/pages/useful-links';
import { RuralSurvey } from './components/pages/rural-services-survey';
import { Contact } from './components/pages/contact';
import { MainContext } from './components/main-context';
import config from './config';

ReactGA.initialize('UA-209301388-1');
ReactGA.pageview(window.location.pathname + window.location.search);

function App() {
  const { setNewsArticles, setPageCount } = useContext(MainContext);
  useEffect(() => {
    const getResults = async () => {
      const [news] = await Promise.all([
        axios.get(`${config.baseUrl}/api/v1/news?page=1`),
      ]);

      setNewsArticles(news.data.data.articles);
      setPageCount(news.data.meta.last_page);
    };
    getResults();
  }, []);

  return (
    <>
      <Router>
        <ScrollToTop />
        <MainNav />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/rural-crime" component={() => <RuralCrime />} />
          <Route exact path="/news" component={News} />
          <Route exact path="/News/Foraging_Taster_Session.aspx">
            <Redirect to="/news" />
          </Route>
          <Route exact path="/News/2012_AGM_BKVC_VoY_winners.aspx">
            <Redirect to="/news" />
          </Route>
          <Route exact path="/News/Plough_Sunday.aspx">
            <Redirect to="/news" />
          </Route>
          <Route exact path="/News/Ash_dieback.aspx">
            <Redirect to="/news" />
          </Route>
          <Route
            exact
            path="/News/Edible_Essex_Allotment_Volunteering_Event.aspx"
          >
            <Redirect to="/news" />
          </Route>
          <Route exact path="/News/Leader.aspx">
            <Redirect to="/news" />
          </Route>
          <Route
            exact
            path="/News/Sir_Thomas_Hughes-Hallett_releases_the_Who_Will_C.aspx"
          >
            <Redirect to="/news/258" />
          </Route>
          <Route exact path="/News/Essex_Police_Rural_Crime_Strategy.aspx">
            <Redirect to="/news/290" />
          </Route>
          <Route exact path="/News/Jingling_without_the_Mingling.aspx">
            <Redirect to="/news/325" />
          </Route>
          <Route exact path="/news/:id" component={NewsStory} />
          <Route exact path="/about-us" component={AboutUs} />
          <Route
            exact
            path="/meetings-and-events"
            component={MeetingsAndEvents}
          />
          <Route
            exact
            path="/essex-rural-strategy"
            component={EssexRuralStrategy}
          />
          <Route exact path="/resources" component={ResourcesPage} />
          <Route exact path="/useful-links" component={UsefulLinks} />
          <Route exact path="/rural-survey" component={RuralSurvey} />
          <Route exact path="/contact" component={Contact} />
          <Route exact path="/Essex_Rural_Strategy.aspx">
            <Redirect to="/essex-rural-strategy" />
          </Route>
          <Route exact path="/RDPE_and_LEADER.aspx">
            <Redirect to="/" />
          </Route>
          <Route exact path="/Fly_Tipping.aspx">
            <Redirect to="/rural-crime/fly-tipping" />
          </Route>
          <Route exact path="/About_Us.aspx">
            <Redirect to="/about-us" />
          </Route>
          <Route exact path="/News.aspx">
            <Redirect to="/news" />
          </Route>
          <Route exact path="/Action_Groups/Community_Action_Group.aspx">
            <Redirect to="/" />
          </Route>
          <Route exact path="/Meetings_and_Events.aspx">
            <Redirect to="/meetings-and-events" />
          </Route>
          <Route exact path="/Documents/Presentations.aspx">
            <Redirect to="/resources" />
          </Route>
          <Route
            exact
            path="/Rural_Crime/Essex_Police_Rural_Crime_Strategy.aspx"
          >
            <Redirect to="/rural-crime/essex-police-rural-crime-strategy" />
          </Route>
          <Route exact path="/Documents.aspx">
            <Redirect to="/resources" />
          </Route>
          <Route exact path="/Documents/Newsletters.aspx">
            <Redirect to="/resources" />
          </Route>
          <Route exact path="/Contact_Us.aspx">
            <Redirect to="/contact" />
          </Route>
          <Route exact path="/Documents/Essex_Rural_Strategy.aspx">
            <Redirect to="/resources" />
          </Route>
          <Route exact path="/Links.aspx">
            <Redirect to="/useful-links" />
          </Route>
          <Route
            exact
            path="/Action_Groups/Community_Action_Group/Community_Case_Studies.aspx"
          >
            <Redirect to="/" />
          </Route>
          <Route exact path="/Action_Groups.aspx">
            <Redirect to="/" />
          </Route>
          <Route exact path="/Fly_Tipping/Press_Releases.aspx">
            <Redirect to="/rural-crime/fly-tipping" />
          </Route>
          <Route
            exact
            path="/RDPE_and_LEADER/LEADER_documents_and_downloads.aspx"
          >
            <Redirect to="/" />
          </Route>
          <Route exact path="/Rural_Crime.aspx">
            <Redirect to="/rural-crime/essex-police-rural-crime-strategy" />
          </Route>
          <Route exact path="/Site_Map.aspx">
            <Redirect to="/" />
          </Route>
          <Route exact path="/Documents/Full_ERP_Minutes.aspx">
            <Redirect to="/resources" />
          </Route>
          <Route exact path="/Action_Groups/Environment_Action_Group.aspx">
            <Redirect to="/" />
          </Route>
          <Route exact path="/?page=100&mode=edit">
            <Redirect to="/" />
          </Route>
          <Route path="/Default.aspx">
            <Redirect to="/" />
          </Route>
          <Route exact path="/Documents/Rural_Services_Survey.aspx">
            <Redirect to="/resources" />
          </Route>
          <Route exact path="/Essex_Police_Rural_Crime_Strategy.aspx">
            <Redirect to="/rural-crime/essex-police-rural-crime-strategy" />
          </Route>
          <Route exact path="/Essex_Police_Rural_Crime_Strategy.aspx">
            <Redirect to="/rural-crime/essex-police-rural-crime-strategy" />
          </Route>
          <Route exact path="/Documents/Newsletters.aspx?page=2411&mode=edit">
            <Redirect to="/resources" />
          </Route>
          <Route exact path="/Fly_Tipping/Environment_Agency_Protocol.aspx">
            <Redirect to="/rural-crime/fly-tipping" />
          </Route>
          <Route exact path="/About_Us/Essex_Rural_Strategy.aspx">
            <Redirect to="/essex-rural-strategy" />
          </Route>
          <Route exact path="/Action_Groups/Economic_Action_Group.aspx">
            <Redirect to="/" />
          </Route>
          <Route exact path="/Essex_Police_Rural_Crime_Day.aspx">
            <Redirect to="/news/299" />
          </Route>
          <Route path="/docs">
            <Redirect to="/resources" />
          </Route>
          <Route component={CustomPage} />
        </Switch>
      </Router>
      <Footer />
      <GlobalStyle />
    </>
  );
}

export default App;
