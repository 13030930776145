import React from 'react';
import { isIE } from 'react-device-detect';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { faCaretRight, faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import theme from '../theme';

const Breadcrumbs = ({ pages }) => {
  const isMobile = useMediaQuery('(max-width:425px)');

  if (isIE || isMobile) {
    return null;
  }

  return (
    <Wrapper id="resources-breadcrumbs">
      <SecondaryPage to="/" home>
        <FontAwesomeIcon icon={faHome} color="#aaa" />
        <span>Home</span>
      </SecondaryPage>
      <FontAwesomeIcon icon={faCaretRight} color={theme.blue} />
      {pages.map((page) => (
        <div key={page.title}>
          {!page.active && (
            <SecondaryPage to={page.link}>{page.title}</SecondaryPage>
          )}
          {page.active && <CurrentPage>{page.title}</CurrentPage>}
          {!page.active && (
            <FontAwesomeIcon icon={faCaretRight} color={theme.blue} />
          )}
        </div>
      ))}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flexbox;
  align-items: center;
  margin: 2rem 0;
  line-height: 1.5;
  flex-wrap: wrap;
`;

const SecondaryPage = styled(Link)`
  cursor: pointer;
  text-decoration: none;
  color: #757575;
  padding: 0 0.5rem 0.5rem 0;
  ${({ home }) => !home && 'margin-left: 0.15rem;'}
  svg {
    margin-right: 0.15rem;
  }
`;

const CurrentPage = styled.span`
  pointer-events: none;
  color: ${theme.blue};
  padding: 0 0.5rem;
`;

export { Breadcrumbs };
