import React from 'react';
import styled from 'styled-components';
import { faExternalLinkSquareAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import theme from '../theme';

const LinkTyle = ({ title, url }) => (
  <Tyle href={url} target="_blank">
    <LinkTitle>{title}</LinkTitle>
    <LinkIcon>
      <FontAwesomeIcon icon={faExternalLinkSquareAlt} />
    </LinkIcon>
  </Tyle>
);

const LinkTitle = styled.div`
  width: 59%;
  flex-grow: 1;
  display: flex;
  align-items: center;
  padding: 1rem;
  color: #757575;
  font-weight: 500;
  border-right: 1px solid rgba(86, 84, 84, 0.2);
  transition: all 0.25s ease-out;
`;

const LinkIcon = styled.div`
  width: 39%;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.25s ease-out;
  svg {
    color: ${theme.green};
    font-size: 2rem;
    transition: all 0.25s ease-out;
  }
`;

const Tyle = styled.a`
  width: 48.5%;
  display: flex;
  border: 1px solid #fff;
  border-radius: 0.1875rem;
  box-shadow: 1px 1px 5px 0px rgba(86, 84, 84, 0.2);
  margin: 0.3125rem;
  padding: 0.5rem 0;
  transition: all 0.25s ease-out;
  &:hover {
    box-shadow: 2px 2px 5px 0px rgba(86, 84, 84, 0.3);
  }
  &:hover > ${LinkTitle} {
    border-right: 1px solid rgba(86, 84, 84, 0.3);
    color: #555;
  }
  &:hover > ${LinkIcon} {
    svg {
      color: ${theme.darkGreen};
    }
  }
  @media screen and (max-width: 1061px) {
    width: 100%;
  }
`;

export { LinkTyle };
