import React from 'react';
import styled from 'styled-components';
import {
  faPhone,
  faPaperPlane,
  faAddressCard,
  faCaretRight,
} from '@fortawesome/free-solid-svg-icons';
import {
  faFacebookSquare,
  faTwitterSquare,
} from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Typography from '@material-ui/core/Typography';
import { Container } from './container';
import footerLogo from '../img/ERP_LOGO_ON_BLUE-01.png';
import RCCELogo from '../img/rcce-logo-small.jpg';
import natureFooter from '../img/natureFooter.png';

const Footer = () => (
  <StyledFooter>
    <Container>
      <FooterSections>
        <FooterSection>
          <img src={footerLogo} alt="ERP logo" width="200px" />
          <div>
            The Essex Rural Partnership (ERP) was formed in 2002, the result of
            a merger between the Essex Rural Renewal Forum and the Essex Rural
            Strategy Steering Group. Since 2012 ERP has been a member of Defra's
            Rural and Farming Network.
          </div>
          <SocialMediaLinks>
            <a
              href="https://twitter.com/EssexRP"
              target="_blank"
              rel="noreferrer"
            >
              <Typography variant="srOnly">Go to Twitter page</Typography>
              <Icon icon={faTwitterSquare} />
            </a>
            <a
              href="https://www.facebook.com/essexruralpartnership/"
              target="_blank"
              rel="noreferrer"
            >
              <Typography variant="srOnly">Go to FAcebook page</Typography>
              <Icon icon={faFacebookSquare} />
            </a>
          </SocialMediaLinks>
        </FooterSection>
        <FooterSection>
          <h3>Useful Links</h3>
          <ul>
            <li>
              <FooterLink>
                <FontAwesomeIcon icon={faCaretRight} color="#fff" />
                <a href="https://acre.org.uk/" target="_blank" rel="noreferrer">
                  Action with Communities in Rural England (ACRE)
                </a>
              </FooterLink>
            </li>
            <li>
              <FooterLink>
                <FontAwesomeIcon icon={faCaretRight} color="#fff" />
                <a
                  href="https://fcn.org.uk/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Farming Community Network (FCN)
                </a>{' '}
              </FooterLink>
            </li>
            <li>
              <FooterLink>
                <FontAwesomeIcon icon={faCaretRight} color="#fff" />
                <a href="https://www.essex.gov.uk/" target="_blank" rel="noreferrer">
                Essex County Council (ECC)
                </a>{' '}
              </FooterLink>
            </li>
            <li>
              <FooterLink>
                <FontAwesomeIcon icon={faCaretRight} color="#fff" />
                <a
                  href="https://www.cpre.org.uk/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Campaign to Protect Rural England (CPRE)
                </a>{' '}
              </FooterLink>
            </li>
            <li>
              <FooterLink>
                <FontAwesomeIcon icon={faCaretRight} color="#fff" />
                <a
                  href="https://www.communityagentsessex.org.uk/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Community Agents Essex
                </a>
                {'   '}
              </FooterLink>
            </li>
            <li>
              <FooterLink>
                <FontAwesomeIcon icon={faCaretRight} color="#fff" />
                <a
                  href="https://www.essex.police.uk/"
                  target="_blank"
                  rel="noreferrer"
                >
                 Essex Police
                </a>
                {'   '}
              </FooterLink>
            </li>
           
            <li>
              <FooterLink>
                <FontAwesomeIcon icon={faCaretRight} color="#fff" />
                <a
                  href="https://www.essexrcc.org.uk/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Rural Community Council Of Essex
                </a>
                {'   '}
              </FooterLink>
            </li>
          </ul>
        </FooterSection>
        <FooterSection>
          <h3>Address</h3>
          <ul>
            <li>
              <FooterLink>
                <FontAwesomeIcon icon={faPhone} color="#fff" />
                <a href="tel:+4498886660000">01376 574330</a>
              </FooterLink>
            </li>
            <li>
              <FooterLink>
                <FontAwesomeIcon icon={faPaperPlane} color="#fff" />
                <a href="mailto:info@essexruralpartnership.org.uk">
                  info@essexruralpartnership.org.uk
                </a>
              </FooterLink>
            </li>
            <li>
              <FooterAddress>
                <FontAwesomeIcon icon={faAddressCard} color="#fff" />
                <ul>
                  <li>c/o Rural Community Council of Essex</li>
                  <li>Threshelfords Business Park</li>
                  <li>Inworth Road, Feering</li>
                  <li>Essex, CO5 9SE</li>
                </ul>
              </FooterAddress>
            </li>
          </ul>
        </FooterSection>
      </FooterSections>
      <SmallPrint>
        <Footnotes>
          <Footnote>
            Registered Charity No. 1097009. Company registered in England and
            Wales No. 4609624
          </Footnote>
          <Footnote>
            Copyright ©2021 Designed & Developed by KAT Marketing
          </Footnote>
        </Footnotes>
        <Logo>
          <img src={RCCELogo} alt="RCCE logo" />
        </Logo>
      </SmallPrint>
    </Container>
  </StyledFooter>
);

const Logo = styled.div`
  display: flex;
  flex-direction: row-reverse;
  width: 50%;
  margin-top: 0.5rem;
  @media (max-width: 425px) {
    width: 100%;
    flex-direction: row;
    margin-top: 2rem;
  }
`;

const Footnotes = styled.div`
  width: 50%;
  @media (max-width: 425px) {
    width: 100%;
  }
`;

const Footnote = styled.p`
  margin-bottom: 0;
  margin-top: 0.5rem;
`;

const SmallPrint = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  font-size: 0.75rem;
  padding-left: 5rem;
  margin-bottom: 2rem;
  @media (max-width: 900px) {
    padding-left: 1rem;
  }
`;

const Icon = styled(FontAwesomeIcon)`
  font-size: 2rem;
  @media (max-width: 425px) {
    font-size: 1.75rem;
  }
`;

const SocialMediaLinks = styled.div`
  a {
    margin-right: 0.5rem;
  }
  margin-top: 1rem;
`;

const FooterLink = styled.div`
  display: flex;
  align-items: center;
  svg {
    font-size: 1rem;
  }
  a {
    margin-left: 0.5rem;
  }
`;

const FooterAddress = styled.div`
  display: flex;
  svg {
    font-size: 1rem;
    margin-top: 0.2rem;
  }
  ul {
    margin-left: 0.5rem;
  }
`;

const FooterSections = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const FooterSection = styled.div`
  flex-grow: 1;
  width: 30%;
  padding: 5rem 2rem 1rem 5rem;
  line-height: 1.7;
  justify-content: space-between;
  h3 {
    margin-bottom: 1rem;
  }
  ul {
    list-style: none;
    ul > li {
      margin-bottom: 0;
    }
  }
  li {
    margin-bottom: 0.5rem;
  }
  a {
    color: white;
  }
  img {
    margin-top: -1rem;
  }
  @media screen and (max-width: 1500px) {
    font-size: 1rem;
  }
  @media screen and (max-width: 900px) {
    width: 100%;
    padding: 1rem;
  }
  @media screen and (max-width: 360px) {
    font-size: 0.8rem;
  }
`;

const StyledFooter = styled.footer`
  position: relative;
  background: url(${natureFooter});
  background-size: cover;
  background-position: center;
  color: white;
  overflow: hidden;
  h3 {
    font-size: 1.2rem;
  }
  @media screen and (max-width: 900px) {
    padding-top: 1rem;
  }
`;

export { Footer };
