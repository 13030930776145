import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { getSnippetSubstring } from '../utils/text-utils';

const LearnMoreTile = ({ text, image, colour, link, snippet }) => {
  const [hovered, setHovered] = useState(false);

  return (
    <Tile>
      <Image src={image} alt="News Article Image" />
      <Description colour={colour}>
        <Heading>
          {text.length >= 65 ? getSnippetSubstring(text, 65) : text}
        </Heading>
        {text.length <= 65 && (
          <Snippet>{getSnippetSubstring(snippet, 100)}</Snippet>
        )}
        <ArticleLink
          to={link}
          colour={colour}
          onMouseOver={() => setHovered(true)}
          onMouseOut={() => setHovered(false)}
        >
          <LearnMore hovered={hovered}>Learn More</LearnMore>
        </ArticleLink>
      </Description>
    </Tile>
  );
};

const LearnMore = styled.span`
  display: flex;
  align-items: center;
`;

const Heading = styled.h3`
  color: white;
  font-weight: 500;
  margin-bottom: 1rem;
  font-size: 1.25rem;
  @media screen and (max-width: 350px) {
    font-size: 1rem;
  }
`;

const Snippet = styled.p`
  font-size: 1rem;
  margin: 0;
  //flex-grow: 1;
  @media screen and (max-width: 1200px) {
    display: none;
  }
`;

// learn-more-section
const Tile = styled.div`
  display: flex;
  height: 15rem;
  margin-bottom: 0.75rem;
  width: 49.5%;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

// learn-more-image
const Image = styled.img`
  display: block;
  width: 50%;
  object-position: center;
  object-fit: cover;
  height: 100%;
`;

// learn-more-description
const Description = styled.div`
  width: 50%;
  ${({ colour }) => `background: ${colour}`};
  height: 100%;
  position: relative;
  padding: 1rem;
  color: white;
  text-transform: none;
  @media screen and (max-width: 910px) {
    padding: 1.5rem;
  }
  @media screen and (max-width: 835px) {
    padding: 1rem;
  }
  @media screen and (max-width: 320px) {
    padding: 0.8rem;
  }
`;

// learn-more-link
const ArticleLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  font-weight: 600;
  padding: 0.5rem 1rem;
  border: 0.15rem solid white;
  color: white;
  background: ${({ colour }) => `${colour}`};
  position: absolute;
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%);
  width: 50%;
  @media screen and (max-width: 1620px) {
    border: none;
    padding: 0;
    text-align: left;
    left: 1rem;
    width: 7rem;
    transform: none;
    justify-content: left;
  }
  @media screen and (max-width: 768px) {
    border: 0.15rem solid white;
    padding: 0.5rem 1rem;
    text-align: center;
    justify-content: center;
    width: 75%;
    left: 50%;
    transform: translateX(-50%);
  }
  @media screen and (max-width: 425px) {
    text-align: left;
    justify-content: left;
    border: none;
    padding: 0;
    text-align: left;
    left: 1rem;
    width: 7rem;
    transform: none;
  }
`;

export { LearnMoreTile };
