import styled from 'styled-components';
import theme from '../theme';

const PageHeading = styled.div`
  padding: 1.5rem 0;
  border-top: 0.15rem solid ${theme.blue};
  border-bottom: 0.15rem solid ${theme.blue};
  color: ${theme.blue};
  text-transform: uppercase;
  h1 {
    font-size: 4rem;
    @media screen and (max-width: 1024px) {
      font-size: 3rem;
    }
    @media screen and (max-width: 425px) {
      font-size: 2rem;
    }
  }
`;

export { PageHeading };
