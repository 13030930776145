import React from 'react';
import { Documents } from './documents';
import { EssexRuralStrategy } from './essex-rural-strategy';
import { FullERPMinutes } from './FullErpMinutes';
import { Presentations } from './Presentations';
import { Newsletters } from './NewsLetters';
import { EssexPoliceRuralCrimeStrategy } from './EssexPoliceRuralCrimeStrategy';
import { EssexWoodlandStrategy } from './EssexWoodlandStrategy';
import { RuralServicesSurvey } from './RuralServicesSurvey';

const DocumentGroups = () => (
  <>
    <Documents />
    <EssexRuralStrategy />
    <FullERPMinutes />
    <Presentations />
    <Newsletters />
    <EssexPoliceRuralCrimeStrategy />
    <EssexWoodlandStrategy />
    <RuralServicesSurvey />
  </>
);

export { DocumentGroups };
