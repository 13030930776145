import styled from 'styled-components';
import theme from '../theme';

const Anchor = styled.a`
  text-decoration: none;
  color: ${theme.blue};
  cursor: pointer;
`;

export { Anchor };
