/* eslint-disable jsx-a11y/mouse-events-have-key-events,  jsx-a11y/mouse-events-have-key-events, jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */

import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import erpLogo from '../img/ERP_LOGO.png';
import theme from '../theme';
import { MobileNav } from './mobile-nav';

const MainNav = () => {
  const [showRuralCrime, setShowRuralCrime] = useState(false);
  const [showMobileNav, setShowMobileNav] = useState(false);
  const { pathname } = useLocation();

  const closeMobileNav = () => setShowMobileNav(false);

  const handleDropdownClickOut = (event) => {
    if (!['showRuralCrime', 'expand-more'].includes(event.target.id)) {
      setShowRuralCrime(false);
    }
  };

  const handleSubMenuEnter = (event) => {
    if (event.key === 'Enter') {
      setShowRuralCrime(true);
    }
  };

  const handleSubmenuTabOut = () => {
    setShowRuralCrime(false);
  };

  const handleShowRuralCrime = () => {
    setShowRuralCrime(true);
  };

  useEffect(() => {
    document.addEventListener('click', handleDropdownClickOut);

    return () => {
      document.removeEventListener('click', handleDropdownClickOut);
    };
  }, []);

  return (
    <>
      <Nav id="main-nav">
        <Link to="/">
          <Logo src={erpLogo} alt="ERP Logo" />
        </Link>
        <LargeMenu>
          <MenuItem
            active={pathname.includes('about-us')}
            to="/about-us"
            role="menuitem"
            onFocus={handleSubmenuTabOut}
          >
            About ERP
          </MenuItem>
          <MenuItem
            active={pathname.includes('essex-rural-strategy')}
            to="/essex-rural-strategy"
            role="menuitem"
            onFocus={handleSubmenuTabOut}
          >
            Essex Rural Strategy
          </MenuItem>
          <DropdownHeading
            onClick={handleShowRuralCrime}
            onKeyDown={(event) => handleSubMenuEnter(event, 'rural-crime')}
          >
            <LinkWrapper active={pathname.includes('rural-crime')}>
              <MenuItem
                id="showRuralCrime"
                active={pathname.includes('rural-crime')}
                noMargin
                role="menuitem"
                aria-haspopup="true"
              >
                Rural Crime
              </MenuItem>
              <DropDownArrow id="expand-more" />
            </LinkWrapper>
            <Dropdown show={showRuralCrime}>
              <DropdownItem>
                <DropdownLink to="/rural-crime/fly-tipping" role="menuitem">
                  Fly tipping
                </DropdownLink>
              </DropdownItem>
              <DropdownItem>
                <DropdownLink
                  to="/rural-crime/essex-police-rural-crime-strategy"
                  role="menuitem"
                >
                  Essex Police Rural Crime Strategy
                </DropdownLink>
              </DropdownItem>
              <DropdownItem>
                <DropdownLink
                  to="/domesticabusehelp"
                  role="menuitem"
                >
                  Domestic Abuse - Help
                </DropdownLink>
              </DropdownItem>
            </Dropdown>
          </DropdownHeading>
          <MenuItem
            active={pathname.includes('news')}
            to="/news"
            onFocus={handleSubmenuTabOut}
          >
            News
          </MenuItem>
          <MenuItem
            active={pathname.includes('meetings-and-events')}
            to="/meetings-and-events"
            onFocus={handleSubmenuTabOut}
          >
            Meetings and Events
          </MenuItem>
          <MenuItem
            active={pathname.includes('resources')}
            to="/resources"
            role="menuitem"
            onFocus={handleSubmenuTabOut}
          >
            Resources
          </MenuItem>
          <MenuItem
            active={pathname.includes('useful-links')}
            to="/useful-links"
            onFocus={handleSubmenuTabOut}
          >
            Useful Links
          </MenuItem>
          <MenuItem
            noMargin
            active={pathname.includes('contact')}
            to="/contact"
            onFocus={handleSubmenuTabOut}
          >
            Contact
          </MenuItem>
        </LargeMenu>
        <MobileMenu>
          <Typography variant="srOnly">Show Menu</Typography>
          <FontAwesomeIcon
            icon={faBars}
            color={theme.blue}
            onClick={() => setShowMobileNav(true)}
          />
        </MobileMenu>
      </Nav>
      <MobileNav show={showMobileNav} closeMobileNav={closeMobileNav} />
    </>
  );
};

const DropDownArrow = styled(ExpandMore)`
  path {
    pointer-events: none;
  }
`;

const Dropdown = styled.ul`
  height: max-content;
  opacity: ${({ show }) => (show ? '1' : '0')};
  list-style: none;
  background: white;
  border-top: 2px solid ${theme.green};
  min-width: 10rem;
  position: absolute;
  top: 5rem;
  z-index: 1000;
  box-shadow: 1px 5px 5px 0 rgba(0, 0, 0, 0.1);
  transition: all 0.25s ease-out;
  li {
    display: ${({ show }) => (show ? 'block' : 'none')};
  }
`;

const DropdownItem = styled.li`
  padding: 1rem 1.5rem;
  color: ${theme.blue};
  border-top: 1px solid #ddd;
  font-weight: 500;
  font-size: 1rem;
  transition: all 0.1s ease-in-out;
  &::last-child {
    border-top: none;
  }
  &:hover {
    background: #eee;
    outline: none;
  }
`;

const DropdownHeading = styled.div`
  cursor: pointer;
`;

const DropdownLink = styled(Link)`
  text-decoration: none;
  display: block;
  color: ${theme.blue};
  &:focus {
    outline: none;
    color: ${theme.green};
  }
`;

const Nav = styled.nav`
  height: 5rem;
  background: white;
  display: flex;
  align-items: center;
  font-size: 1rem;
  font-weight: 500;
  padding: 0 17rem;
  @media screen and (max-width: 1325px) {
    justify-content: space-between;
  }
  @media screen and (max-width: 1775px) {
    padding: 0 10rem;
  }
  @media screen and (max-width: 1550px) {
    padding: 0 6rem;
  }
  @media screen and (max-width: 1421px) {
    padding: 0 3rem;
  }
  @media screen and (max-width: 550px) {
    padding: 0 1rem;
  }
`;

const LargeMenu = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: 1325px) {
    display: none;
  }
`;

const MobileMenu = styled.button`
  border: none;
  background: white;
  cursor: pointer;
  svg {
    font-size: 1.5rem;
  }
  @media screen and (min-width: 1325px) {
    display: none;
  }
`;

const Logo = styled.img`
  margin-right: 2rem;
  width: 15rem;
  margin-top: 0.5rem;
  @media screen and (max-width: 768px) {
    width: 12rem;
  }
  @media screen and (max-width: 425px) {
    width: 10rem;
  }
`;

const MenuItem = styled(Link)`
  text-decoration: none;
  color: ${({ active }) => (active ? `${theme.green}` : `${theme.blue}`)};
  ${({ noMargin }) => !noMargin && `margin-right: 1rem;`}
  &:hover {
    color: ${theme.green};
  }
  transition: all 0.25s ease-out;
`;

const LinkWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 1rem;
  a {
    margin-right: 0.25rem;
  }
  svg {
    color: ${({ active }) => (active ? `${theme.green}` : `${theme.blue}`)};
    transform: translate(-20%, 2%);
    transition: all 0.25s ease;
  }
  &:hover > svg {
    color: ${theme.green};
  }
`;

export { MainNav };
