import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { Container } from '../../container';
import { PageHeading } from '../../page-heading';
import { GreenAccent } from '../../green-accent';
import { PageGrid, PageGridItem } from '../../page-grid';
import { useWindowSize } from '../../../hooks/use-window-size';
import { SideBarContent } from '../../side-bar-content';
import { PageTitle } from '../../page-title';
import { Breadcrumbs } from '../../breadcrumbs';
import { DocumentGroups } from './DocumentGroups';
import { SubMenu } from './SubMenu';

const ResourcesPage = () => {
  const { width } = useWindowSize();
  const { hash } = useLocation();

  const pages = [{ title: 'Resources', active: true }];

  const sublinks = [
    {
      href: 'essex-rural-strategy',
      text: 'Essex Rural Strategy',
    },
    {
      href: 'full-erp-minutes',
      text: 'Full ERP Minutes',
    },
    {
      href: 'presentations',
      text: 'Presentations',
    },
    {
      href: 'newsletters',
      text: 'Newsletters',
    },
    {
      href: 'essex-police-rural-crime-strategy',
      text: 'Essex Police Rural Crime Strategy',
    },
    {
      href: 'essex-woodland-strategy',
      text: 'Essex Woodland Strategy',
    },
    {
      href: 'rural-services-survey',
      text: 'Rural Services Survey',
    },
  ];

  useEffect(() => {
    if (hash === '#newsletters') {
      setTimeout(() => {
        const el = document.getElementById('newsletters');
        if (el) {
          el.scrollIntoView({ behavior: 'smooth' });
        }
      }, 500);
    }
  }, []);

  return (
    <>
      <PageTitle title="Essex Rural Partnership - Resources" />
      <PageHeading>
        <Container>
          <h1>
            <GreenAccent>ERP</GreenAccent> Resources
          </h1>
        </Container>
      </PageHeading>
      <Container>
        <Breadcrumbs pages={pages} />
        <Wrapper>
          {width <= 768 && (
            <>
              <SubMenu sublinks={sublinks} />
              <DocumentGroups />
            </>
          )}
          {width > 768 && (
            <PageGrid>
              <PageGridItem
                position="left"
                width={width <= 1024 ? '64%' : '69%'}
              >
                <SubMenu sublinks={sublinks} />
                <DocumentGroups />
              </PageGridItem>
              <PageGridItem
                position="right"
                width={width <= 1024 ? '34%' : '29%'}
                margin="0.3125rem 0 0 0"
              >
                <StyledSideBarContent />
              </PageGridItem>
            </PageGrid>
          )}
        </Wrapper>
      </Container>
    </>
  );
};

const StyledSideBarContent = styled(SideBarContent)`
  margin-top: 20px;
`;

const Wrapper = styled.div`
  margin-top: 2rem;
  margin-bottom: 2rem;
`;

export { ResourcesPage };
