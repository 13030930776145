import { useEffect, useState } from 'react';
import axios from 'axios';
import { DocumentsHeading } from './DocumentsHeading';
import { Loader } from '../../loader';
import { BackToTop } from './BackToTop';
import config from '../../../config';

const RuralServicesSurvey = () => {
  const [content, setContent] = useState();

  useEffect(() => {
    const getResults = async () => {
      const [contentResults] = await Promise.all([
        axios.get(`${config.baseUrl}/api/v1/page/rural-services-survey-main`),
      ]);

      setContent(contentResults.data.data[0]);
    };
    getResults();
  }, []);

  return (
    <>
      <DocumentsHeading id="rural-services-survey">
        Rural Services Survey
      </DocumentsHeading>
      {content && <div dangerouslySetInnerHTML={{ __html: content.content }} />}
      {!content && <Loader />}
      <BackToTop />
    </>
  );
};

export { RuralServicesSurvey };
