import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { Container } from '../../container';
import { Breadcrumbs } from '../../breadcrumbs';
import { FlyTipping } from './fly-tipping';
import { RuralCrimeStrategy } from './rural-crime-strategy';
import { PageHeading } from '../../page-heading';
import { PageTitle } from '../../page-title';

const RuralCrime = () => {
  const getBreadcrumbsPages = (page) => [
    { title: 'Rural Crime', active: false },
    { title: page, active: true },
  ];

  return (
    <>
      <Switch>
        <Route exact path="/rural-crime/fly-tipping">
          <PageTitle title="Fly Tipping" />
        </Route>
        <Route exact path="/rural-crime/essex-police-rural-crime-strategy">
          <PageTitle title="Essex Police Rural Crime Strategy" />
        </Route>
      </Switch>
      <PageHeading>
        <Container>
          <h1>Rural Crime</h1>
        </Container>
      </PageHeading>
      <Container>
        <Route exact path="/rural-crime/fly-tipping">
          <Breadcrumbs pages={getBreadcrumbsPages('Fly-Tipping')} />
          <FlyTipping />
        </Route>
        <Route exact path="/rural-crime/essex-police-rural-crime-strategy">
          <Breadcrumbs
            pages={getBreadcrumbsPages('Essex Police Rural Crime Strategy')}
          />
          <RuralCrimeStrategy />
        </Route>
      </Container>
    </>
  );
};

export { RuralCrime };
