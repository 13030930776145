import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import Pagination from '@material-ui/lab/Pagination';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { format } from 'date-fns';
import styled from 'styled-components';
import { Container } from '../../container';
import { SmallArticle } from './small-article';
import { PageHeading } from '../../page-heading';
import { GreenAccent } from '../../green-accent';
import { Headline } from './headline';
import { Breadcrumbs } from '../../breadcrumbs';
import { Loader } from '../../loader';
import config from '../../../config';
import defaultImage from '../../../img/acorns.jpg';
import { PageTitle } from '../../page-title';
import { MainContext } from '../../main-context';

const News = () => {
  const { newsArticles, pageCount } = useContext(MainContext);
  const [currentArticles, setCurrentArticles] = useState();
  const [headline, setHeadline] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const isMedium = useMediaQuery('(max-width:455px)');
  const isSmall = useMediaQuery('(max-width:383px)');

  const pages = [{ title: 'News', active: true }];

  useEffect(() => {
    setCurrentArticles(newsArticles);
    setHeadline(newsArticles ? newsArticles[0] : null);
  }, [newsArticles]);

  const handlePaginationChange = async (event, page) => {
    setCurrentPage(page);
    window.scrollTo(0, 700);
    const results = await axios.get(
      `${config.baseUrl}/api/v1/news?page=${page}`
    );

    const { articles } = results.data.data;

    if (page > 1) {
      setCurrentArticles(articles);
    } else {
      setCurrentArticles(newsArticles);
    }
  };

  const getPaginationSize = () => {
    if (isSmall) {
      return 'small';
    }

    if (isMedium) {
      return undefined;
    }

    return 'large';
  };

  return (
    <>
      <PageTitle title="Essex Rural Partnership - News" />
      <PageHeading>
        <Container>
          <h1>
            <GreenAccent>ERP</GreenAccent> news
          </h1>
        </Container>
      </PageHeading>
      <Container>
        <Breadcrumbs pages={pages} />
        {headline && (
          <Headline
            title={headline.heading}
            image={
              headline.image
                ? `${config.baseUrl}/public/${headline.image}`
                : defaultImage
            }
            date={format(new Date(headline.date), 'do MMMM yyyy')}
            link={`/news/${headline.id}`}
          />
        )}
        {currentArticles?.length && (
          <Articles>
            {currentArticles.map((article) => (
              <SmallArticle
                key={article.id}
                id={article.id}
                image={
                  article.image
                    ? `${config.baseUrl}/public/${article.image}`
                    : defaultImage
                }
                date={format(new Date(article.date), 'do MMMM yyyy')}
                title={article.heading}
                snippet={article.overview}
              />
            ))}
          </Articles>
        )}
        {(!headline || !currentArticles?.length) && <Loader />}
        <PaginationWrapper>
          <Pagination
            count={pageCount}
            page={currentPage}
            onChange={handlePaginationChange}
            size={getPaginationSize()}
          />
        </PaginationWrapper>
      </Container>
    </>
  );
};

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
`;

const Articles = styled.div`
  margin-top: 2rem;
  margin-bottom: 1rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export { News };
