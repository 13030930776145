import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import theme from '../theme';

const SecondaryNavTile = ({ text, icon, link, hash }) => (
  <Tyle>
    <Link to={{ pathname: link || '#', hash }}>
      <span>{text}</span>
      <div>
        <FontAwesomeIcon icon={icon} />
      </div>
    </Link>
  </Tyle>
);

const Tyle = styled.div`
  width: 20%;
  padding: 1rem;
  text-align: center;
  flex-grow: 1;
  @media screen and (max-width: 768px) {
    width: 50%;
  }
  a {
    color: ${theme.blue};
    &:hover {
      color: ${theme.green};
    }
    display: flex;
    height: 10rem;
    flex-direction: column;
    justify-content: space-between;
    transition: all 0.25s ease-out;
    @media screen and (max-width: 1023px) {
      height: 10rem;
    }
    @media screen and (max-width: 650px) {
      height: 8rem;
    }
    @media screen and (max-width: 570px) {
      height: 8.5rem;
    }
    @media screen and (max-width: 550px) {
      height: 8rem;
    }
    @media screen and (max-width: 500px) {
      height: 9rem;
    }
    @media screen and (max-width: 480px) {
      height: 7.5rem;
    }
    @media screen and (max-width: 420px) {
      height: 7.75rem;
    }
  }
  span {
    font-size: 1.5rem;
    font-weight: 500;
    @media screen and (max-width: 1023px) {
      font-size: 1.2rem;
    }
    @media screen and (max-width: 650px) {
      font-size: 1.1rem;
    }
    @media screen and (max-width: 640px) {
      font-size: 1.25rem;
    }
    @media screen and (max-width: 480px) {
      font-size: 1.1rem;
    }
    @media screen and (max-width: 390px) {
      font-size: 0.9rem;
    }
  }
  svg {
    font-size: 6rem;
    @media screen and (max-width: 1023px) {
      font-size: 5.5rem;
    }
    @media screen and (max-width: 650px) {
      font-size: 5rem;
    }
    @media screen and (max-width: 550px) {
      font-size: 4.5rem;
    }
    @media screen and (max-width: 480px) {
      font-size: 4.25rem;
    }
    @media screen and (max-width: 440px) {
      font-size: 4rem;
    }
    @media screen and (max-width: 390px) {
      margin-bottom: 1rem;
    }
    @media screen and (max-width: 330px) {
      margin-bottom: 0.5rem;
    }
  }
  div {
    padding-top: 1.5rem;
  }
`;

export { SecondaryNavTile };
